import styled from "styled-components"
import media from "styled-media-query"

export const PostHeader = styled.header`
  color: #fff;
  margin: 2rem auto;
  max-width: 80rem;
  padding: 5rem 5rem 0;
  padding-right: 20%;

  ${media.lessThan("large")`
    padding: 0 20px;
  `}
  
  ${media.lessThan("medium")`
    text-align: center;
    padding: 20px;
    margin-top: 6rem;
  `}
`

export const PostTitle = styled.h1`
  width: 100%;
  font-size: 4rem;
  color: var(--main-color);
  line-height: 1.25;
  font-weight: 600;
  font-family: var(--fontSerif);

  ${media.lessThan("medium")`
    text-align: center;
    padding: 20px;
    font-size: 2.2rem;
  `}
`

export const PostCategory = styled.span`
  width: 100%;
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--main-color);
  margin-top: 1rem;
  font-family: var(--fontSerif);
  margin-bottom: 0.9rem;
  line-height: 1.45rem;
`

export const PostDescription = styled.h2`
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.6rem;
  color: var(--text-color);
  margin-top: 1rem;
  font-family: var(--fontSansSerif);

  ${media.lessThan("large")`
    font-size: 1.25rem;
  `}
`

export const PostDate = styled.p`
  font-size: 1.125rem;
  color: #718096;
  width: 100%;
  margin-bottom: 1rem;
`

export const MainContent = styled.section`
  display: flex;
  flex-wrap: row wrap;
  justify-content: start;
  margin: auto;
  max-width: 80rem;
  padding: 5rem 5rem 0;
  padding-right: 20%;
  font-family: var(--fontSansSerif);
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #ffffff40;

  ${media.lessThan("large")`
    max-width: 100%;
    padding: 0 20px;
  `}

  div {
    width: 100%;
  }
  p {
    max-width: 80ch;
    font-size: 1.25rem;
    color: var(--text-color);
    margin: 1.5rem 0;
    line-height: 1.4;

    ${media.lessThan("medium")`
      line-height: 1.6;
    `}
  }
  h2 {
    width: 100%;
    font-size: 2.2rem;
    color: var(--main-color);
    margin-top: 2.3rem;
    margin-bottom: 2.3rem;
    line-height: 1.25;
    font-weight: 700;
    font-family: var(--fontSerif);

    ${media.lessThan("medium")`
      font-size: 1.6rem;
    `}
  }
  h4 {
    width: 100%;
    font-size: 1.7rem;
    color: var(--main-color);
    margin-top: 2.3rem;
    margin-bottom: 2.3rem;
    line-height: 1.25;
    font-weight: 700;
    font-family: var(--fontSansSerif);

    ${media.lessThan("medium")`
      font-size: 1.4rem;
    `}
  }
  ul,
  ol {
    list-style: disc;
    padding-left: 2.5rem;
    margin: 0 auto 1.6rem;
  }
  li {
    padding: 0.625rem 0;
    max-width: 80ch;
    font-size: 1.2rem;
    color: var(--text-color);
    margin-top: 0.9rem;
    margin-bottom: 0.9rem;
    line-height: 1.4;

    & > ul {
      margin-bottom: 0;
    }
  }
  p,
  li {
    code {
      word-wrap: break-word;
    }
    
    code.language-text {
      margin: 0 0.2em;
      border-radius: 8px;
      padding: 0.1rem 0.4em;
    }
  }
  img {
  }
  iframe {
    padding: 0 1.6rem 1.6rem;
    width: 100%;
  }
  blockquote {
    color: #fff;
    border-left: 0.3rem solid #1fa1f2;
    padding: 0 1.875rem;
    margin: 3.125rem auto;
  }
  hr {
    border: 1px solid #38444d;
    margin: 3rem auto;
  }
  #twitter-widget-0,
  .instagram-media,
  .twitter-tweet {
    margin: 20px auto !important;
  }
  strong {
    font-weight: 700;
  }
  .gatsby-resp-image-wrapper + em {
    display: block;
    margin: 1rem;
    margin-top: -0.8rem;
    font-family: var(--fontSansSerif);
    color: var(--text-color);
    border-left: 2px solid var(--text-color);
    padding-left: .3rem;
    font-size: 1.1rem;
    opacity: 0.8;
  }
  .gatsby-resp-image-wrapper {
    box-shadow: var(--elevation1);
    border-radius: 0.5rem;
    max-width: 100%;
    height: auto;
    margin: 2rem 0;
    margin-right: 2.5rem;
  }
  .gatsby-resp-image-background-image {
    z-index: 2;
    opacity: 1 !important;
    border-radius: 25rem;
  }
  .gatsby-resp-image-image {
    box-shadow: none !important;
    transition: opacity 0.2s;
    border-radius: 0.5rem;
    &.lazyload {
      opacity: 0;
    }
    &.lazyloaded {
      opacity: 1;
      z-index: 3;
    }
  }
  .gatsby-highlight {
    padding: 0 1.6rem 1.6rem;

    ${media.lessThan("large")`
      padding: 0 2rem 2rem;
    `}
  }
  .instagram-media {
    margin: 1rem auto !important;
  }
  a {
    font-weight: 600;
    color: var(--purple-accent);
    text-decoration: none;
    font-family: var(--fontSansSerif);
    transition: opacity 150ms ease-in-out;
    &:hover {
      opacity: 0.8;
    }
  }
`
