import styled from "styled-components"
import media from "styled-media-query"
import Anilink from "gatsby-plugin-transition-link/AniLink"

export const RecommendedWrapper = styled.section`
  background: #f9f7f4;
  background: var(--bg-body-color);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
`

export const RecommendedTitle = styled.span`
  width: 100%;
  margin: 0 4rem;
  padding: 1rem;
  text-align: center;
  font-weight: 600;
  font-size: 1.1rem;
  letter-spacing: 1px;
`

export const RecommendedLink = styled(Anilink)`
  display: flex;
  align-items: center;
  max-width: 50%;
  padding: 2rem;
  margin: 0 4rem;
  text-decoration: none;
  font-weight: 700;
  font-size: 1.1rem;
  font-family: var(--fontSansSerif);
  border: none;
  transition: all 250ms ease-in-out;
  color: var(--main-color);

  ${media.lessThan("large")`
    font-size: 0.9rem;
    padding: 0.7rem;
    margin: 0.3rem;
    margin-top: 2rem;
  `}

  &:hover {
    background: var(--purple);
    border-radius: 4px;
    box-shadow: 0 1px 6px #1c192b52, 0 0 0 1px #1c192b9e;
    color: #fff;
  }
  &.next {
    justify-content: flex-end;
  }
  &.previous:before {
    content: "\\2190";
    margin-right: 0.5rem;
  }
  &.next:after {
    content: "\\2192";
    margin-left: 0.5rem;
  }
`