import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import RecommendedPosts from "../components/blog/RecommendedPosts"

import "./style.css"

import * as S from "../components/blog/Post/styled"

const PortfolioPost = ({ data, pageContext }) => {
  const [isLoaded, setIsLoaded] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(false)
    }, 0)
  }, [])

  const post = data.markdownRemark

  const next = pageContext.nextPost
  const previous = pageContext.previousPost

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={post.frontmatter.image}
      />
      <S.PostHeader className={isLoaded ? "fade" : "faded"}>
        <S.PostDate>{post.timeToRead} min de leitura</S.PostDate>
        <S.PostCategory>{post.frontmatter.type}</S.PostCategory>
        <S.PostCategory>{post.frontmatter.category}</S.PostCategory>
        <S.PostTitle>{post.frontmatter.title}</S.PostTitle>
        <S.PostDescription>{post.frontmatter.description}</S.PostDescription>
      </S.PostHeader>
      <S.MainContent className={isLoaded ? "fade slowed" : "faded"}>
        <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
      </S.MainContent>
      <RecommendedPosts next={next} previous={previous} />
    </Layout>
  )
}

export const query = graphql`
  query PortfolioPost($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        description
        category
        date(formatString: "DD [de] MMMM [de] YYYY", locale: "pt-br")
        image
      }
      html
      timeToRead
    }
  }
`

export default PortfolioPost
